import { useRef } from 'react';

import VideojsPlayer from './VideojsPlayer';

interface SourceStreamVideoComponentProps {
  streamLink: string;
  type: string,
}


const SourceStreamVideoComponent = ({streamLink, type}: SourceStreamVideoComponentProps) => {

  const playerRef = useRef(null);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [{
      src: streamLink,
      type: type
      //'application/x-mpegURL'
    }]
  };

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      //videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      //videojs.log('player will dispose');
    });
  };


	return (
      <div className=''>
        <VideojsPlayer options={videoJsOptions} />
      </div>
	);
};

export default SourceStreamVideoComponent;
