import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import SourceStreamVideoComponent from './components/SourceStreamVideoComponent';
import { Apis } from './constants/Apis';

function App() {

  const [videoProps, SetVideoProps] = useState<{streamLink: string,type: string} | null>(null);

  const fetchSourceStreamLink = async () => {
    await fetch(`${Apis.BASE_URL}/showStreamSource`)
    .then((response) => response.json())
    .then((jsonData) => {
      console.log(jsonData);
      switch (true) {
        case jsonData.data.streaming_link.includes('m3u8'):
          SetVideoProps({streamLink: jsonData.data.streaming_link, type: 'application/x-mpegURL'})
          break;

        case jsonData.data.streaming_link.includes('mp4'):
          SetVideoProps({streamLink: jsonData.data.streaming_link, type: 'video/mp4'})
          break;
      
        default:
          SetVideoProps({streamLink: jsonData.data.streaming_link, type: 'application/x-mpegURL'})
          break;
      }
      
    })
    .catch((err) => {
      console.log(err.message);
    });
  }

  useEffect(() => {
		fetchSourceStreamLink();
		
	}, []);

  return (
    <div className="App">
      {videoProps !== null && (
        <SourceStreamVideoComponent streamLink={videoProps.streamLink} type={videoProps.type} />
      )}
    </div>
  );
}

export default App;
